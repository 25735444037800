const siteConfig = {
  name: 'Easy Pairing: Helping organizations pair and group individuals for success',
  alt_name: 'Easy Pairing',
  description: 'A SAAS platform that helps organizations pair and group individuals for success',
  url: process.env.NEXT_PUBLIC_DOMAIN,
  ogImage: '',
  loading_bar_color: '#ADD8E6',
  links: {  
    linkedin: 'https://www.linkedin.com/company/easypairing'
  },
  seo_title: 'Easy Pairing | AI-Powered Group Matching & Team Formation Software',
  seo_description: 'Transform group formation with Easy Pairing&quot;s intelligent matching algorithm. Create optimal teams, roommate matches, and mentorship pairs using advanced AI. Start your free trial today.'
};

export default siteConfig;
