'use client';

import configuration from '@/lib/config/site';
import Link from 'next/link';
import { SocialIcons } from './Icons';
import routes from '@/lib/config/routes';
import { Input } from './ui/Input';
import { Button } from './ui/Button';
import { toast } from 'react-toastify';
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/Form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { NewsletterSubscribersSchema, NewsletterSubscribers } from '@/lib/types/validations';
import { useRouter } from 'next/navigation';
import { CreateNewsletterSubscribers } from '@/lib/API/Database/newsletterSubscribers/mutations';
import { SendSubscribersEmail } from '@/lib/API/Services/newsletterSubscribers/newsletterSubscribers';
import { Icons } from '@/components/Icons';


export default function Footer() {
  const { footer_nav } = routes;
  const router = useRouter();

  const form = useForm<NewsletterSubscribers>({
    resolver: zodResolver(NewsletterSubscribersSchema),
    defaultValues: {
      email: '',
    },
  });


      const { 
        reset,
        register,
        formState: { isSubmitting }
      } = form;

      const onSubmit = async (values: NewsletterSubscribers) => {
        const { email } = values;

        try {
          await CreateNewsletterSubscribers({ email }); // API call to save the email in MongoDB with Prisma
          await SendSubscribersEmail({ email }); // Send a welcome email using Resend

          reset(); 
          toast.success('You have successfully subscribed to our newsletter');
          router.refresh(); 
        } catch (err) {
          toast.error('Failed to subscribe. Please try again.');
          throw err;
        }
      };

  return (
    <footer className="bg-slate-800 mt-8">
      <div className="mx-auto max-w-7xl px-6 pb-6 pt-16 lg:px-8 ">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  {footer_nav.about.title}
                </h3>
                <ul role="list"
className="mt-4 space-y-4">
                  {footer_nav.about.routes.map((item) => (
                    <li key={item.title}>
                      <Link
                        href={item.link}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  {footer_nav.resources.title}
                </h3>
                <ul role="list"
className="mt-4 space-y-4">
                  {footer_nav.resources.routes.map((item) => (
                    <li key={item.title}>
                      <Link
                        href={item.link}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  {footer_nav.legal.title}
                </h3>
                <ul role="list"
className="mt-4 space-y-4">
                  {footer_nav.legal.routes.map((item) => (
                    <li key={item.title}>
                      <Link
                        href={item.link}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold leading-6 text-white">
              Subscribe to our newsletter
            </h3>
            <p className="mt-2 text-sm leading-6 text-gray-300">
              The latest news, articles, and resources, sent to your inbox weekly.
            </p>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} 
                      className="mt-6 sm:flex sm:max-w-md"
                >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormMessage />
                      <FormControl>
                        <Input
                          {...register('email')}
                          type="text"
                          placeholder=" Enter your Email"
                          className="bg-background-light dark:bg-background-dark"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <div className='mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0'>
                  <Button disabled={isSubmitting} 
                          className="w-full"
                  >
                    {isSubmitting && <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />}
                    Submit
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </div>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 ">
          <p className="text-xs leading-5 text-slate-300 py-4 text-center md:text-left">
            &copy; 2024 Easy Pairing, Inc. All rights reserved.
          </p>
          <div className="text-white py-4 justify-self-center">
            <div className="flex items-end">
              <Link href={configuration.links.linkedin}
                    target="_blank" 
                    rel="noopener noreferrer"
              >
                <SocialIcons.Linkedin className="mx-8"
                                      size={24} 
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
