import { Icons } from '@/components/Icons';
import { RolesE } from '../types/enums';

const routes = {
  routes_dashboard: [
    {
      title: 'Dashboard',
      url: '/',
      icon: Icons.Home,
      roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
      isActive: true,
      isVisible: true
    },
    {
      title: 'Portfolio',
      icon: Icons.Unplug,
      roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
      isActive: true,
      isVisible: true,
      items: [
        {
          title: 'Projects',
          url: '/projects',
          roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
          isActive: true
        },
        {
          title: 'Analytics & Scenarios',
          url: '/analytics',
          roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
          isActive: true
        }
      ]
    },
    {
      title: 'Questions Bank',
      url: '/questionsbank',
      icon: Icons.Landmark,
      roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
      isActive: true,
      isVisible: true
    },
    {
      title: 'Profile Settings',
      url: '/profile_settings',
      icon: Icons.UserCog2,
      roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
      isActive: true,
      isVisible: true
    },
    {
      title: 'Recent Activities',
      url: '/recent_activity',
      icon: Icons.Clock,
      roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
      isActive: true,
      isVisible: false
    },
    {
      title: 'Org Settings',
      icon: Icons.Settings,
      roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
      isActive: true,
      isVisible: true,
      items: [
        {
          title: 'Profile',
          url: '/org_profile',
          roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
          isActive: true
        },
        {
          title: 'Billing & Subscription',
          url: '/billing_subscription',
          roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
          isActive: true
        },
        {
          title: 'Members',
          url: '/team_settings',
          roles: [RolesE.ADMIN, RolesE.OWNER, RolesE.MEMBER],
          isActive: true
        }
      ]
    }
  ],
  routes_dashboard_subroutes: {
    projects: {
      editor: { title: 'Create', link: '/projects/editor' },
      viewer: { title: 'View', link: '/projects/viewer' }
    },
  },
  routes_marketing: [
    { title: 'How It Works', link: '/howitworks' },
    { title: 'Use Cases', link: '/usecases' },
    { title: 'Pricing', link: '/pricing' },
    { title: 'FAQs', link: '/faq' },    
    { title: 'Contact Us', link: '/contact' }
  ],
  redirects: {
    dashboard: {
      dashboardBase: '/dashboard/',
      toDashboard: '/',
      settings: {
        toPaymentResult: '/billing_subscription/payment_result',      
      },
      todos: { toMyTodos: '/todos/my-todos', createTodos: '/todos/create' }
    },
    auth: {
      toLogin: '/auth/login',
      requireAuth: '/auth/login',
      authConfirm: '/auth/confirmed',
      callback: '/api/auth-callback'
    },
    user: {
      toUserDashboard: '/user/dashboard',
      toOrgInvite: '/user/org-invite'
    }
  },
  footer_nav: {
    about: {
      title: 'About',
      routes: [
        { title: 'Pricing', link: '/pricing' },
        { title: 'FAQs', link: '/faq' }
      ]
    },
    resources: {
      title: 'Resources',
      routes: [
        { title: 'Blog', link: '/blog' },
        { title: 'Help Center', link: '/docs/getting-started' }
      ]
    },
    legal: {
      title: 'Legal',
      routes: [
        { title: 'Privacy Policy', link: '/privacypolicy' },
        { title: 'Terms and Conditions', link: '/termsandconditions' }
      ]
    }
  }
};

export default routes;
