import Link from 'next/link';
import Image from 'next/image';
import siteConfig from '@/lib/config/site';
import HeaderIcon from '/public/static/images/icon/easypairing.png';

interface LogoProps {
  href?: string;
  className?: string;
}

export function MainLogoText({ href = '/' }: LogoProps) {
  return (
    <Link 
      href={href}
      className="inline-flex items-center gap-3 hover:opacity-90 transition-opacity"
    >
      <Image 
        src={HeaderIcon}
        alt={siteConfig.alt_name}
        width={24}
        height={24}
        className="h-6 w-auto"
        priority
      />
      <span className="font-bold text-lg tracking-tight bg-gradient-to-r from-orange-500 via-orange-600 to-orange-800 text-transparent bg-clip-text">
  {siteConfig.alt_name}
</span>
    </Link>
  );
}

// export function MainLogoIcon({ href = '/', className }: LogoProps) {
//   return (
//     <Link 
//       href={href} 
//       className="inline-flex hover:opacity-90 transition-opacity"
//       aria-label={siteConfig.alt_name}
//     >
//       <Image 
//         src={HeaderIcon}
//         alt={siteConfig.alt_name}
//         width={24}
//         height={24}
//         className="h-6 w-auto"
//         priority
//       />
//     </Link>
//   );
// }



// import siteConfig from '@/lib/config/site';
// import Link from 'next/link';
// // import { Icons } from '@/components/Icons';

// import Image from 'next/image';
// import HeaderIcon from '/public/static/images/icon/easypairing.png';

// // export const MainLogoText = ({ href }: { href?: string }) => {
// //   const link = href ? href : '/';
// //   return (
// //     <Link href={link}
// //           className="items-center flex space-x-2 md:flex"
// //     >
// //       <Icons.Command />
// //       <span className="font-bold md:inline-block">{siteConfig.alt_name}</span>
// //     </Link>
// //   );
// // };
// export const MainLogoText = ({ href }: { href?: string }) => {
//   const link = href ? href : '/';
//   return (
//     <Link href={link}
//           className="items-center flex space-x-2 md:flex"
//     >
//       <Image src={HeaderIcon}
//               alt="Icon"
//               width={16}
//               height={16}
//       />
      
//       <span className="font-bold md:inline-block">{siteConfig.alt_name}</span>
//     </Link>
//   );
// };

// export const MainLogoIcon = () => {
//   return (
//     <Link href="/"
//       className="w-4 h-4">
//       <Image src={HeaderIcon}
//               alt="Icon"
//               width={16}
//               height={16}
//       />
//       {/* <Icons.Command /> */}
//     </Link>
//   );
// };
