'use client';

import { buttonVariants } from '@/components/ui/Button';
import Link from 'next/link';
import { cn } from '@/lib/utils/helpers';
import routes from '@/lib/config/routes';
import { MainLogoText } from '@/components/MainLogo';
import { useState } from 'react';
import { Menu, X } from 'lucide-react';
//import { ThemeDropDownMenu } from '../../../components/ThemeDropdown';

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  return (
<header className="fixed top-0 left-0 right-0 z-50 bg-white border-b">
      <div className="container mx-auto">
        <div className="flex h-16 items-center justify-between px-4">
          {/* Logo */}
          <div className="flex-shrink-0">
            <MainLogoText />
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:block">
            <nav className="flex items-center gap-6">
              {routes.routes_marketing.map((item) => (
                <Link
                  key={item.link}
                  href={item.link}
                  className="text-lg font-bold text-gray-700 hover:text-gray-900 transition-colors"
                >
                  {item.title}
                </Link>
              ))}
            </nav>
          </div>

          {/* Auth Button */}
          <div className="hidden md:flex items-center gap-4">
            <Link
              href="/auth/login"
              className={cn(
                buttonVariants({ variant: 'secondary', size: 'sm' }),
                'font-bold'
              )}
            >
              Sign In
            </Link>
            <Link
              href="/auth/signup"
              className={cn(
                buttonVariants({ variant: 'secondary', size: 'sm' }),
                'font-bold'
              )}
            >
              Sign Up
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6 text-gray-700" />
            ) : (
              <Menu className="h-6 w-6 text-gray-700" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            <nav className="flex flex-col py-4 px-4 space-y-4 bg-white border-t">
              {routes.routes_marketing.map((item) => (
                <Link
                  key={item.link}
                  href={item.link}
                  className="text-base font-bold text-gray-700 hover:text-gray-900 transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.title}
                </Link>
              ))}
              <Link
                href="/auth/login"
                className={cn(
                  buttonVariants({ variant: 'secondary' }),
                  'font-bold w-full justify-center'
                )}
                onClick={() => setIsMenuOpen(false)}
              >
                Sign In
              </Link>
              <Link
              href="/auth/signup"
              className={cn(
                buttonVariants({ variant: 'secondary', size: 'sm' }),
                'font-bold'
              )}
            >
              Sign Up
            </Link>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};